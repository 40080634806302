import * as React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown'

const PolicyPrivacyPage = ({ data }) => {
  const { t } = useTranslation()
  return (
    <Layout>
      <Seo title={data.allStrapiPrivacyPolicy.nodes[0].title} />
      <section className='py-8 md:py-16 px-4'>
        <div className='w-full max-w-screen-lg mx-auto'>
          <h1 className='text-primary font-semibold text-xl md:text-4xl mb-10'>
            {data.allStrapiPrivacyPolicy.nodes[0].title}
          </h1>
          <div className='entry'>
            <ReactMarkdown children={data.allStrapiPrivacyPolicy.nodes[0].content} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PolicyPrivacyPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allStrapiPrivacyPolicy(filter: {locale: {eq: $language}}) {
      nodes {
        content
        title
        id
      }
    }
  }
`;
